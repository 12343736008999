// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

require("@rails/ujs").start()
require("turbolinks").start()
require("@rails/activestorage").start()
require("channels")
require("flatpickr")

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.

const images = require.context('../images', true)
const imagePath = (name) => images(name, true)

// Application styles
import 'stylesheets/application'

// JS libraries
import 'jquery/dist/jquery'
import * as jQuery from 'jquery/src/jquery'
import modernizr from 'modernizr'

// Foundation
import 'foundation-sites/js/foundation'

// Flatpickr
import flatpickr from "flatpickr"
import { French } from "flatpickr/dist/l10n/fr.js"
import { English } from "flatpickr/dist/l10n/default.js"
import { German } from "flatpickr/dist/l10n/de.js"

// Owl carousel
import 'owl.carousel'

// PhotoSwipe
import 'libs/photoswipe/photoswipe.js'

// Application js
import 'javascripts/application-main'
