jQuery.fn.load = function(callback){ $(window).on("load", callback) };

$(document).on("turbolinks:load", function() {
  $(function(){
    Foundation.global.namespace = '';
    $(document).foundation({
      equalizer: {
        equalize_on_stack:  true
      }
    });
    if ($('#modalRedirect').length > 0){
      $('#modalRedirect').foundation('reveal', 'open');
    }
  });

  var stickyNavbar = function() {
    if ($(window).scrollTop() > $('header').height()) {
      $('.sticky').addClass('sticked-on');
    } else {
      $('.sticky').removeClass('sticked-on');
    }
  }
  stickyNavbar();
  $(window).scroll(stickyNavbar);

  var footerPosition = function() {
    setInterval(function(){
      $('footer').css('position', $('body').height() > $('.off-canvas-wrap').height() + $('footer').height() ? "fixed" : "static");
    }, 500);
  };
  footerPosition();
  $(window).ready(footerPosition);
  $(window).resize(footerPosition);

  $('.button-send').click(function(){
    $("#loader").fadeIn();
  });

  $('li.has-dropdown.not-click').click(function() {
    return false;
  });

  $('li.has-dropdown.not-click li a').click(function() {
    if ( $(this).hasClass('off-turbolinks') ) {
      window.location.href = $(this).attr('href');
    } else {
      Turbolinks.visit($(this).attr('href'));
    }
  });

  $('.menu-icon').click(function(e) {
    if ( !$('body').hasClass('no-scroll') ) {
      $('html, body').animate({ scrollTop: 0 }, 'slow');
    }

    if ( $(".menu-shadow").length ) {
      $(".menu-shadow").remove();
    } else {
      $('section.main-section').prepend("<a class='menu-shadow'></a>");
    }

    setTimeout(function() {
      $('body').toggleClass('no-scroll');
    }, 400)
  });

  // Fix navbar arrow
  $('.not-click').click(function() {
    $('.not-click').removeClass('moved');
  });

  // Flatpickr
  let locale = 'fr';

  if ( location.pathname.split('/')[1].length ) {
    switch(location.pathname.split('/')[1]) {
      case 'en': {
        locale = 'en';
        break;
      }
      case 'de': {
        locale = 'de';
        break;
      }
      default: {
        locale = 'fr';
      }
    }
  }

  flatpickr('#reservation_visit_date', {
    dateFormat: "d.m.Y",
    locale
  });

  flatpickr('#reservation_visit_time', {
    enableTime: true,
    noCalendar: true,
    dateFormat: "H:i",
    time_24hr: true
  });

  secureMail();
  menuActive();
  initCarousel();

  if ( $('#gallery').length ) { photoSwipe(); }


  function initCarousel() {
    setInterval(function(){
      if ( $('.owl-carousel').length ) {
        $('.owl-carousel').owlCarousel({
          loop: true,
          nav: false,
          dots: false,
          autoplay:true,
          autoplayTimeout: 2500,
          items: 6,
          mouseDrag: false,
          responsive : {
            0 : {
              items : 1
            },
            480 : {
              items : 3
            },
            998 : {
              items : 6
            }
          }
        });
      }
    }, 2500)
  }

  function photoSwipe() {
    console.log('Initialize PhotoSwipe gallery');
    let items = [];

    $('#gallery figure').each(function(){
      let _link = $(this).find('a'),
        item = {
          src: _link.attr('href'),
          w: _link.data('width'),
          h: _link.data('height'),
          title: _link.data('caption')
        };
      items.push(item);
    });

    let pswpElement = $('.pswp')[0];

    $('#gallery a').click(function(event){
      event.preventDefault();

      let options = {
        index: $(this).closest('li').index(),
        bgOpacity: 0.85,
        showHideOpacity: true
      };
      // Initialize PhotoSwipe
      let gallery = new PhotoSwipe(pswpElement, PhotoSwipeUI_Default, items, options);
      gallery.init();
    });
  }

  function secureMail() {
    $('a[title*="[at]"][title*="[dot]"]').each(function() {
      let email = $(this).attr('title').split('[at]').join('@').split('[dot]').join('.');
      $(this).attr('href', 'mailto:' + email.toLowerCase());
      $(this).attr('title', '');

      if ($(this).text().length === 0) $(this).text(email);
    });
  }

  function menuActive() {
    $( '.top-bar .dropdown .active' ).each(function() {
      $( this ).closest('.has-dropdown').addClass('active');
    });
  }

  // reservation form
  $("#reservation-form input[type='number']").keypress(function() {
    return (event.charCode == 8 || event.charCode == 0 || event.charCode == 13) ? null : event.charCode >= 48 && event.charCode <= 57
  });

  setInterval(function(){
    $('.grecaptcha-badge').css('display', 'none');
    $('figure iframe').css('width', '100%');
  }, 500);

  // hide video on mobile
  if ( $("section.video").length && $("body").prop("clientWidth") <= 768 ) {
    $("section.video").remove();
  }

  //Turbolinks off
  $(".off-turbolinks").click(function(e){
    e.preventDefault();
    window.location.href = $(this).attr('href');
  });
});
